import { createMuiTheme } from "@material-ui/core";
import { responsiveFontSizes } from "@material-ui/core/styles"

let theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#484848',
      main: '#171717',
      dark: '#000000',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#819ca9',
      main: '#546e7a',
      dark: '#29434e',
      contrastText: '#FFFFFF'
    },
    background: {
      default: '#262626',
    },
    text:{
      primary: '#FFFFFF'
    }
  },
});
// theme = createMuiTheme({})
theme = responsiveFontSizes(theme);

export default theme;
